<template>
    <ValidationObserver ref="excelForm">
        <b-row>
            <b-col cols="12" md="6" lg="4">
                <ValidationProvider name="semester_id" rules="required" v-slot="{ errors }">
                    <b-form-group :label="$t('semester')">
                        <semesters-selectbox v-model="filter.semester_id
                                            " :setActive="true">
                        </semesters-selectbox>
                        <b-form-invalid-feedback v-if="errors[0]"
                                                 v-html="errors[0]"></b-form-invalid-feedback>
                    </b-form-group>
                </ValidationProvider>
            </b-col>
            <b-col cols="12" md="6" lg="4">
                <ValidationProvider name="faculty_code" v-slot="{ errors }">
                    <b-form-group :label="$t('faculty')">
<!--                        <faculty-selectbox v-model="surveyResultFilter.faculty_code-->
<!--                                            ">-->
<!--&lt;!&ndash;                        </faculty-selectbox>&ndash;&gt;-->
<!--&lt;!&ndash;                        <b-form-invalid-feedback v-if="errors[0]"&ndash;&gt;-->
<!--&lt;!&ndash;                                                 v-html="errors[0]"></b-form-invalid-feedback>&ndash;&gt;-->
                        <multiselect
                            v-model="filter.faculty_code"
                            :options="facultiesCodeOption"
                            :placeholder="$t('select')"
                            label="text"
                            track-by="value"
                            :select-label="''"
                            :selected-label="''"
                            :deselect-label="''"
                        />
                    </b-form-group>

                </ValidationProvider>
            </b-col>
            <b-col cols="12" md="6" lg="4">
                <ValidationProvider name="instructor_id" v-slot="{ errors }">
                    <b-form-group :label="$t('teaching_staff')">
                        <staff-auto-complete v-model="filter.instructor_id
                                            " :validateError="errors[0]" />
                    </b-form-group>
                </ValidationProvider>
            </b-col>
            <b-col cols="12" md="6" lg="4">
                <ValidationProvider name="department_code" v-slot="{ errors }">
                    <b-form-group :label="$t('department')">
<!--                        <department-selectbox v-model="surveyResultFilter.department_code-->
<!--                                            " :multiple="true" -->
<!--                                              :faculty_code="surveyResultFilter.faculty_code" -->
<!--                                              :validate-error="errors[0]" />-->
                        <multiselect
                            v-model="filter.department_code"
                            :options="departmentCodeOption"
                            :placeholder="$t('select')"
                            label="text"
                            track-by="value"
                            :select-label="''"
                            :selected-label="''"
                            :deselect-label="''"
                        />
                    </b-form-group>
                </ValidationProvider>
            </b-col>

            <b-col cols="12" md="6" lg="4">
                <ValidationProvider name="filter.program_code" v-slot="{ errors }">
                    <b-form-group :label="$t('program')">
                        <multiselect
                            v-model="filter.program_code"
                            :options="programCodeOption"
                            :placeholder="$t('select')"
                            :select-label="''"
                            :selected-label="''"
                            :deselect-label="''"
                            label="text"
                            track-by="value"
                        />
                    </b-form-group>
                </ValidationProvider>
            </b-col>
        </b-row>
        <b-row>
            <!-- <b-col cols="3" md="2" lg="2">
                <b-button type="button" block variant="primary" class="btn-lg mr-2">
                    {{ $t("search") }}
                </b-button>
            </b-col> -->
            <b-col cols="3" md="2" lg="2">
                <b-button variant="success" block @click="excelDownload">
                    {{ "EXCEL" }}
                </b-button>
            </b-col>
        </b-row>
    </ValidationObserver>
</template>
<script >
import qs from "qs";
import SurveyService from "../../../services/SurveyService";

export  default {
    props:{
      formId:{
          type:Number
      }
    },
    data(){
        return{
            departmentCodeOption:[],
            facultiesCodeOption:[],
            programCodeOption:[],
            filter: {},
        }
    },

    mounted() {
    this.getSurveysData(this.formId)
    },
    watch:{
       formId(newValue){
           this.formId=newValue
       }
    },
    methods:{
        getSurveysData(id){
            return SurveyService.ReportFilter(id)
                .then(response =>{
                    let data = response.data.data
                    data.faculties.forEach(item=>{
                        this.facultiesCodeOption.push({
                            value: item.code,
                            text: item.code + ' - ' + this.getLocaleText(item, 'name'),
                        })
                    })
                    data.departments.forEach(item=>{
                        this.departmentCodeOption.push({
                            value: item.code,
                            text: item.code + ' - ' + this.getLocaleText(item, 'name'),
                        })
                    })
                    data.programs.forEach(item=>{
                        this.programCodeOption.push({
                            value: item.code,
                            text: item.code + ' - ' + this.getLocaleText(item, 'name'),
                        })
                    })
                }).catch(err=>{
                    this.showErrors(err)
                })

        },
        async excelDownload() {
            this.filter.survey_id =  this.formId
            this.filter.semester_id=this.filter.semester_id?this.filter.semester_id:null
            this.filter.faculty_code =this.filter.faculty_code?this.filter.faculty_code.value:null
            this.filter.department_code =this.filter.department_code?this.filter.department_code.value:null
            this.filter.program_code =this.filter.program_code?this.filter.program_code.value:null
            const isValid = await this.$refs.excelForm.validate();
            if (isValid) {
                const config = {
                    params: {
                      filter:{
                          'survey_id':this.filter.survey_id,
                          'semester_id':this.filter.semester_id,
                          'faculty_code':this.filter.faculty_code,
                          'program_code':this.filter.program_code,
                          'department_code':this.filter.department_code,
                      }
                    },
                    paramsSerializer: (params) =>
                        qs.stringify(params, { encode: false }),
                };
                SurveyService.report(config)
                    .then((response) => {
                        this.$toast.success(this.$t('api.' + response.data.message))
                        this.$router.push('/job/logs/detail/'+response.data.data.log_id)
                    }).catch(err=>{
                        this.showErrors(err)
                })
            }
        },
    }
}
</script>
